import { PersonalizedAccountHero } from 'components/src/AccountHero'
import { ComponentConfig } from 'components/src/utils/ComponentOverridesContext'

import AddressBookIcon from './components/AddressBookIcon'
import BreadcrumbIcon from './components/BreadcrumbIcon'
import CartItemContainer from './components/cart/CartItemContainer'
import CartItemImage from './components/cart/CartItemImage'
import CartItemInfoContainer from './components/cart/CartItemInfoContainer/CartItemInfoContainer'
import DashboardIcon from './components/DashboardIcon'
import Logo from './components/Logo'
import MemberCardTopBar from './components/MemberCardTopBar'
import NotFoundBackgroundImages from './components/NotFoundPage/NotFoundBackgroundImages'
import OrdersIcon from './components/OrdersIcon'
import PickupPointIcon from './components/PickupPointIcon'
import PostalAddressIcon from './components/PostalAddressIcon'
import ProductInfoHeader from './components/ProductInfo/ProductInfoHeader'
import SuccessPageBackgroundImages from './components/SuccessPage/SuccessPageBackgroundImages'
import SuccessPageButtons from './components/SuccessPage/SuccessPageButtons'
import UserDetailsIcon from './components/UserDetailsIcon'
import VoucherEuroIcon from './components/VoucherEuroIcon/VoucherEuroIcon'
import ThemeOverrides from './theme/ThemeOverrides'

const componentsConfig: ComponentConfig = {
    CartItemContainer,
    CartItemImage,
    CartItemInfoContainer,
    AccountHero: PersonalizedAccountHero,
    AddressBookIcon,
    BreadcrumbIcon,
    DashboardIcon,
    Logo,
    NotFoundBackgroundImages,
    OrdersIcon,
    PickupPointIcon,
    PostalAddressIcon,
    ProductInfoHeader,
    SuccessPageBackgroundImages,
    SuccessPageButtons,
    UserDetailsIcon,
    ThemeOverrides,
    VoucherEuroIcon,
    MemberCardTopBar,
}

export default componentsConfig
